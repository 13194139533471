// import Case from '../../../api/cases/case';
import pullLog from '../../../api/pullLogs/pullLog'
import fileDownload from "js-file-download";

const pullLogs = new pullLog();

const pullLogModule = {
    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
        loadPull: false,
        conec_name:'test'
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        loadPull(state) {
            return state.loadPull;
        },
        lookups(state) {
            return state.lookups;
        },
        conec_name(state){
            return state.conec_name;
        }
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, user) => {
            state.item = user;
        },
        SET_LOADPULL(state, loadPull) {
            state.loadPull = loadPull;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
        SET_CONEC_NAME(state,conec_name){
            state.conec_name = conec_name
        }
    },
    actions: {
        pullLogs({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                pullLogs.list(payload.query)
                    .then(response => {
                        console.log("asd",response);
                        commit('SET_ITEMS', response);
                        commit('SET_TOTAL_ITEMS', response.length);
                        commit('SET_LOOKUPS', response);
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
            });
        },
        pullData({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADPULL', true);
                commit('SET_CONEC_NAME', id);
                console.log('id',id);
                pullLogs.get(id).then(response => {
                    console.log("pullData");
                        commit('SET_ITEM', response.data);
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                        commit('SET_LOADPULL', false);
                        commit('SET_CONEC_NAME', 'test');
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                        commit('SET_LOADPULL', false);
                        commit('SET_CONEC_NAME', 'test');

                    });
            });
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                pullLogs.put(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },

        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                pullLogs.remove(id)
                    .then(response => {
                        resolve();
                    })
            });
        },
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                cases.restore(id)
                    .then(response => {
                        resolve();
                    })
            });
        },

        export ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                pullLogs.export(payload)
                    .then(response => {
                        fileDownload(response, 'instructors.xlsx');
                        resolve();
                    })
            });
        },

    
    },
};

export default pullLogModule;