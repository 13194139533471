import Resource from '../../api/resource'
import request from '../../utils/request';

class Setting extends Resource {
    constructor() {
        super('settings');
    }




    pullData(){
        return request({
            url: '/settings/pull',
            method: 'get',
           
        });
    }

    deleteData(){
        return request({
            url: '/settings/delete',
            method: 'get',
           
        });
    }
    
}
 


export {Setting as default};
