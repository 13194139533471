import Resource from '../resource'
import request from '../../utils/request';

class pullLog extends Resource {
    constructor() {
        super('pullLogs');
    }

    // pullData(payload){
    //     return request({
    //         url: `/cases/${payload.id}`,
    //         method: 'post',
    //         data:payload.query,
    //     });
    // }


}

export {pullLog as default};
