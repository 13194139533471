import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie';

let auth = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import user_settings from './routes/user_settings'
import administration from './routes/administration'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0,}
    },
    routes: [
        {path: '/', redirect: {name: 'dashboard'},},
        ...dashboard,
        ...pages,
        ...user_settings,
        ...administration,

        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn()
    if (!isLoggedIn && !to.meta.redirectIfLoggedIn) {
        return next({name: 'auth-login'})
    }
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) {
            return next({name: 'auth-login'})
        }
        return next({name: 'misc-not-authorized'})
    } else {
        return next()
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
    const isLoggedIn = isUserLoggedIn()
    if (to.name == 'auth-login' && isLoggedIn ) {
        router.push({name: 'dashboard'})
    }
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
