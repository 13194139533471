export default [{
        path: '/settings/logs',
        name: 'logs',
        component: () =>
            import ('@/views/administration/logs/index'),
        meta: { pageTitle: 'Logs', breadcrumb: [{ text: 'Logs', active: true }] },
    },
    {
        path: '/settings/logs/view/:id',
        name: 'log',
        component: () =>
            import ('@/views/administration/logs/show'),
        meta: {
            pageTitle: 'Manage',
            breadcrumb: [
                { text: 'Logs', to: '/settings/logs' },
                { text: 'Log', active: true },
            ],
        },
    },
    {
        path: '/archives',
        name: 'archives',
        component: () =>
            import ('@/views/administration/archive/index'),
        meta: { pageTitle: 'archives', breadcrumb: [{ text: 'Archives', active: true }] },
    },
    {
        path: '/editor/:id',
        name: 'editor',
        component: () =>
            import ('@/views/administration/archive/components/editor'),
        meta: { pageTitle: 'archives', breadcrumb: [{ text: 'Editor', active: true }] },
    },
    {
        path: '/management',
        name: 'management',
        component: () =>
            import ('@/views/administration/management/management'),
        meta: { pageTitle: 'Settings', breadcrumb: [{ text: 'Management', active: true }] },
    },
    {
        path: '/management/admission',
        name: 'admission',
        component: () =>
            import ('@/views/administration/management/settings/index'),
        meta: {
            pageTitle: 'Settings',
            breadcrumb: [
                { text: 'Management', to: '/management' },
                { text: 'Settings', active: true },
            ],
        },
    },
    {
        path: '/management/admission/view/:id',
        name: 'admission-show',
        component: () =>
            import ('@/views/administration/management/settings/show'),
        meta: {
            pageTitle: 'admission',
            breadcrumb: [
                { text: 'Management', to: '/management' },
                { text: 'Settings', active: true },
            ],
        },
    },

    {
        path: '/management/admission/add',
        name: 'settings-add',
        component: () =>
            import ('@/views/administration/management/settings/edit'),
        meta: {
            pageTitle: 'admission',
            breadcrumb: [
                { text: 'Management', to: '/management' },
                { text: 'Settings Add', active: true },
            ],
        },
    },

    {
        path: '/management/admission/edit/:id',
        name: 'settings-edit',
        component: () =>
            import ('@/views/administration/management/settings/edit'),
        meta: {
            pageTitle: 'admission',
            breadcrumb: [
                { text: 'Management', to: '/management' },
                { text: 'admission', active: true },
            ],
        },
    },
    {
        path: '/management/marks',
        name: 'marks',
        component: () =>
            import ('@/views/administration/management/marks/index'),
        meta: { pageTitle: 'marks', breadcrumb: [{ text: 'management', to: '/management' }, { text: 'marks' }] },
    },
    {
        path: '/management/marks/edit/:id',
        name: 'edit_mark',
        component: () =>
            import ('@/views/administration/management/marks/edit'),
        meta: {
            pageTitle: 'marks',
            breadcrumb: [{ text: 'management', to: '/management' }, { to: '/management/marks', text: 'marks' }, { text: 'mark' }],
        },

    },
    {
        path: '/management/marks/add',
        name: 'add_mark',
        component: () =>
            import ('@/views/administration/management/marks/edit'),
        meta: {
            pageTitle: 'marks',
            breadcrumb: [{ text: 'management', to: '/management' }, { to: '/management/marks', text: 'marks' }, { text: 'mark' }],
        },
    },
    {
        path: '/management/markCategories',
        name: 'markCategories',
        component: () =>
            import ('@/views/administration/management/markCategories/index'),
        meta: { pageTitle: 'markCategories', breadcrumb: [{ text: 'management', to: '/management' }, { text: 'mark_categories' }] },
    },
    {
        path: '/management/markCategories/edit/:id',
        name: 'edit_markCategory',
        component: () =>
            import ('@/views/administration/management/markCategories/edit'),
        meta: {
            pageTitle: 'markCategories',
            breadcrumb: [{ text: 'management', to: '/management' }, { to: '/management/markCategories', text: 'mark_categories' }, {
                text: 'mark_category',

            }],
        },

    },
    {
        path: '/management/markCategories/add',
        name: 'add_markCategory',
        component: () =>
            import ('@/views/administration/management/markCategories/edit'),
        meta: {
            pageTitle: 'markCategories',
            breadcrumb: [{ text: 'management', to: '/management' }, { to: '/management/markCategories', text: 'mark_categories' }, {
                text: 'mark_category',

            }],
        },

    },
    {
        path: '/management/stages',
        name: 'stages',
        component: () =>
            import ('@/views/administration/management/term_stage/index'),
        meta: {
            pageTitle: 'term_stages',
            breadcrumb: [{ text: 'Management', to: '/management' }, { text: 'Term Stages', active: true }],
        },
    },
    {
        path: '/management/stages/add',
        name: 'add_term_stage',
        component: () =>
            import ('@/views/administration/management/term_stage/edit'),
        meta: {
            pageTitle: 'term_stages',
            breadcrumb: [{ text: 'Management', to: '/management' }, { text: 'Term Stages', active: true }],
        },
    },
    {
        path: '/management/stages/edit/:id',
        name: 'edit_term_stage',
        component: () =>
            import ('@/views/administration/management/term_stage/edit'),
        meta: {
            pageTitle: 'term_stages',
            breadcrumb: [{ text: 'Management', to: '/management' }, { text: 'Term Stages', active: true }],
        },
    },
    {
        path: '/management/stages/:id',
        name: 'term_stage',
        component: () =>
            import ('@/views/administration/management/term_stage/show'),
        meta: {
            pageTitle: 'term_stage',
            breadcrumb: [{ text: 'Management', to: '/management' }, { text: 'Term Stages', active: true }],
        },
    },

]