// import Case from '../../../api/cases/case';
import Setting from '../../../api/cases/settings'
import fileDownload from "js-file-download";

const settings = new Setting();

const settingsModule = {
    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        // status(state) {
        //     return state.item.removed;
        // },
        lookups(state) {
            return state.lookups;
        },
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, user) => {
            state.item = user;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
    },
    actions: {
        settings({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                settings.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response);
                    
                        resolve(response);
                        console.log(response);
                    })
            });
        },
        pullData() {
            return new Promise((resolve, reject) => {
                settings.pullData()
                    .then(response => {
                    
                        resolve(response);
                        console.log(response);
                    })
            });
        },

        deleteData() {
            return new Promise((resolve, reject) => {
                settings.deleteData()
                    .then(response => {
                    
                        resolve(response);
                        console.log(response);
                    })
            });
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                settings.put(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        get({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                settings.get(id).then(response => {
                        commit('SET_ITEM', response.data);
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                settings.remove(id)
                    .then(response => {
                        resolve();
                    })
            });
        },
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                settings.restore(id)
                    .then(response => {
                        resolve();
                    })
            });
        },

        export ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                settings.export(payload)
                    .then(response => {
                        fileDownload(response, 'instructors.xlsx');
                        resolve();
                    })
            });
        },

        removeDepartmentLink({ commit }, payload) {
            return new Promise((resolve, reject) => {
                settings.removeDepartmentLink(payload)
                    .then(response => {
                        resolve(response);
                    }).catch(error=>{
                        reject(error)
                    });
            });
        },
    },
};

export default settingsModule;