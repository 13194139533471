// import Case from '../../../api/cases/case';
import Branch from '../../../api/branches/branch'
import fileDownload from "js-file-download";

const branches = new Branch();

const branchModule = {
    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        
        lookups(state) {
            return state.lookups;
        },
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, user) => {
            state.item = user;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
    },
    actions: {
        branches({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                branches.list(payload.query)
                    .then(response => {
                        console.log("asd",response);
                        commit('SET_ITEMS', response);
                        commit('SET_TOTAL_ITEMS', response.length);
                        commit('SET_LOOKUPS', response);
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
            });
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                branches.put(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        get({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                branches.get(id).then(response => {
                    console.log("mero",response.data);
                        commit('SET_ITEM', response.data);
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        sendMail({ commit }, {branchId,type}) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                console.log(type);
                branches.sendMail(branchId,type).then(response => {
                    console.log("mero",response);
                        commit('SET_ITEM', response.data);
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                branches.remove(id)
                    .then(response => {
                        resolve();
                    })
            });
        },
        // restore({ commit }, id) {
        //     return new Promise((resolve, reject) => {
        //         cases.restore(id)
        //             .then(response => {
        //                 resolve();
        //             })
        //     });
        // },

        // export ({ commit }, payload) {
        //     return new Promise((resolve, reject) => {
        //         patients.export(payload)
        //             .then(response => {
        //                 fileDownload(response, 'instructors.xlsx');
        //                 resolve();
        //             })
        //     });
        // },

    
    },
};

export default branchModule;