import Resource from '../../api/resource'
import request from '../../utils/request';

class CaseReview extends Resource {
    constructor() {
        super('reviews');
    }

    // export(payload){
    //     return request({
    //         url: `/instructors/export`,
    //         method: 'post',
    //         responseType: "blob",
    //         data:payload,
    //     });
    // }
    // removeDepartmentLink(payload) {
    //     return request({
    //         url: `/${this.uri}/remove-department-link`,
    //         method: 'post',
    //         data: payload,
    //     })
    // }


        save(id , query) {
        return request({
            url: `save/review${id ? `/${id}` : ''}`,
            method: 'put',
            data: query,
        })
    }



}

export {CaseReview as default};
