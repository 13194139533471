// import Case from '../../../api/cases/case';
import Case from '../../../api/cases/case'
import fileDownload from "js-file-download";

const cases = new Case();

const caseModule = {
    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        // status(state) {
        //     return state.item.removed;
        // },
        lookups(state) {
            return state.lookups;
        },
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, user) => {
            state.item = user;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
    },
    actions: {
        cases({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                cases.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response.data);
                        commit('SET_TOTAL_ITEMS', response.meta.count);
                        commit('SET_LOOKUPS', response.meta.lookup);
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
            });
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                cases.put(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        changeStatus({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                cases.changeStatus(payload).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        get({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                cases.get(id).then(response => {
                        commit('SET_ITEM', response.data);
                        commit('app/UPDATE_PAGE_DETAILS', response.data, { root: true });
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                cases.remove(id)
                    .then(response => {
                        resolve();
                    })
            });
        },
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                cases.restore(id)
                    .then(response => {
                        resolve();
                    })
            });
        },
        
        export ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                cases.export(payload)
                    .then(response => {
                        fileDownload(response, 'cases.csv');
                        resolve();
                    })
            });
        },

        removeDepartmentLink({ commit }, payload) {
            return new Promise((resolve, reject) => {
                cases.removeDepartmentLink(payload)
                    .then(response => {
                        resolve(response);
                    }).catch(error=>{
                        reject(error)
                    });
            });
        },
    },
};

export default caseModule;