import Resource from '../resource'
import request from '../../utils/request';

class Branch extends Resource {
    constructor() {
        super('branches');
    }

    sendMail(branchId,type) {
        return request({
          url: `/${this.uri}/send/mail/${branchId}/${type}`,
          method: 'get',
        //   params: {
        //     type: type
        //   },
          data: type,
        });
        
      }


}

export {Branch as default};
