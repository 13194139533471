export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      public: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      public: true,
    },
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-error-connections',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },









  {
    path: '/patients',
    name: 'patients',
    component: () => import('@/views/patients/index.vue'),
    meta: {
      public: true,
      pageTitle: 'Patients ',
      breadcrumb: [
          { to: '/patients', text: 'Patients' },
       
      ],
  },
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import('@/views/cases/index.vue'),
    meta: {
      public: true,
      pageTitle: 'Cases ',
      breadcrumb: [
          { to: '/cases', text: 'Cases' },
       
      ],
  },
  },


  {
    path: '/patient/:id?/:review_id?',
    name: 'patient-show',
    component: () => import('@/views/patients/show.vue'),


    meta: {
      public: true,
      pageTitle: 'Patients',
      breadcrumb: [
          { to: '/patients', text: 'Patients' },
          { text: 'Patient View', active: true }
       
      ],
  },
 
  },




  {
    path: '/case/:id?/:review_id?',
    name: 'case-show',
    component: () => import('@/views/cases/show.vue'),


    meta: {
      public: true,
      pageTitle: 'Cases',
      breadcrumb: [
          { to: '/cases', text: 'Cases' },
          { text: 'Case View', active: true }
       
      ],
  }},
  
  {
    path: '/cases/collected/data',
    name: 'collected-data',
    component: () => import('@/views/cases/logs.vue'),


    meta: {
      public: true,
      pageTitle: 'Collected Data',
      breadcrumb: [
          { to: '/cases/collected/data', text: 'Collected Data' },
        
       
      ],
  },
 
}
,

{
  path: '/reports/doctors',
  name: 'reports-doctors',
  component: () => import('@/views/reports/doctors.vue'),


  meta: {
    public: true,
    pageTitle: 'Reports',
    breadcrumb: [
        { to: '/reports/doctors', text: 'Doctors Reports' },
      
     
    ],
},

},
{
  path: '/reports/branches',
  name: 'reports-branches',
  component: () => import('@/views/reports/branches.vue'),


  meta: {
    public: true,
    pageTitle: 'Reports',
    breadcrumb: [
        { to: '/reports/branches', text: 'Branches Reports' },
      
     
    ],
},

},



{
  path: '/reports/auditors',
  name: 'reports-auditors',
  component: () => import('@/views/reports/auditors.vue'),


  meta: {
    public: true,
    pageTitle: 'Reports',
    breadcrumb: [
        { to: '/reports/auditors', text: 'Auditors Reports' },
      
     
    ],
},

},

{
  path: '/reports/over-all',
  name: 'reports-over-all',
  component: () => import('@/views/reports/over_all.vue'),


  meta: {
    public: true,
    pageTitle: 'Reports',
    breadcrumb: [
        { to: '/reports/over-all', text: 'Over All Performance Reports' },
      
     
    ],
},

}
,
{
  path: '/branches',
  name: 'branches',
  component: () => import('@/views/branches/index.vue'),
  meta: {
    public: true,
    pageTitle: 'Branches ',
    breadcrumb: [
        { to: '/branches', text: 'Branches' },
     
    ],
},
},


{
  path: '/branches/edit/:id',
  name: 'branches-edit',
  permission: 'access_users',
  component: () => import('@/views/branches/components/show'),
  meta: {
    pageTitle: 'Edit Branch',
    breadcrumb: [
      { to: '/branches', text: 'branches' },
      { text: 'edit branch', active: true }
    ],
  },
},

{
  path: '/branches/report/:id',
  name: 'branches-report',
  permission: 'access_users',
  component: () => import('@/views/branches/components/report'),
  meta: {
    pageTitle: 'Branch Report',
    breadcrumb: [
      { to: '/branches', text: 'branches' },
      { text: 'Branch Report', active: true }
    ],
  },
},

// add pull
{
  path: '/pullLogs',
  name: 'pullLogs',
  component: () => import('@/views/pullLogs/index.vue'),
  meta: {
    public: true,
    pageTitle: 'pullLogs ',
    breadcrumb: [
        { to: '/pullLogs', text: 'Sync log' },
     
    ],
},
},

]
