import Resource from '../../api/resource'
import request from '../../utils/request';

class Patient extends Resource {
    constructor() {
        super('patients');
    }

   


}

export {Patient as default};
