import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// users
import usersModule from './modules/user_settings/users/user'
import caseModule from './modules/cases/case'
import patientModule from './modules/patients/patient'
import branchModule from './modules/branches/branch'
//add pull
import pullLogModule from './modules/pullLogs/pullLog'
import caseReviewModule from './modules/cases/case_review'
import settingsModule from './modules/cases/settings'
// import studentModule from './modules/student/students/student'
// import employeeModule from './modules/user_settings/employees/employee'
// import instructorModule from './modules/user_settings/instructors/instructor'

// program
// import programModule from './modules/academic_settings/programs/program'
// import facultyModule from './modules/academic_settings/faculities/faculity'
// import bylawModule from './modules/academic_settings/bylaws/bylaw'
// import courseModule from './modules/academic_settings/courses/course'
// import departmentModule from './modules/academic_settings/departments/departments'

// study

// import offeringModule from './modules/study/offerings/offering'
// import termModule from './modules/study/terms/term'
// import registrationModule from './modules/study/registrations/registration'
// import sectionModule from './modules/study/sections/section'

// examination

// import examModule from './modules/examination/exams/exams'
// import proctorModule from './modules/examination/proctors/proctors'
// import preferenceModule from './modules/examination/preferences/preferences'
// import scheduleModule from './modules/examination/schedule/schedule'

// financial
//
// import paymentModule from './modules/student/payments/payment'
// import transactionModule from './modules/student/transactions/transaction'
// import creditModule from './modules/student/credits/credit'

// system
import roleModule from './modules/user_settings/roles/role'
import logModule from './modules/administration/logs/log'
// import markCategoryModule from './modules/administration/markCategories/markCategory'
// import markModule from './modules/administration/marks/mark'
import dashboardModule from './modules/system/dashboard'
import lookupsModule from './modules/system/lookups'
// import archiveModule from './modules/administration/archive/archive'
// import certificateModule from '@/store/modules/student/certificates/certificate'
// import applicantModule from '@/store/modules/student/applicants/applicant'
// import questionModule from '@/store/modules/quality/questions/question'
// import formModule from '@/store/modules/quality/forms/form'
// import graduate_questionnaireModule from '@/store/modules/quality/graduateQuestionnaires/questionnaire'
// import student_questionnaireModule from '@/store/modules/quality/studentQuestionnaires/questionnaire'
// import faculty_questionnaireModule from '@/store/modules/quality/facultyQuestionnaires/questionnaire'
// import projectModule from '@/store/modules/research/projects/projects'
// import publicationModule from '@/store/modules/research/publications/publications'
// import thesesModule from '@/store/modules/research/theses/theses'
// import termStageModule from './modules/administration/termStage/termStage'
import settingModule from './modules/administration/management/settings'

// import chatModule from './modules/request/chat/chat'

// students modules
// import offeringsRegistrationModule from './modules/student/registrations/offerings_registration'
// Services Settings moudles
// import certificateSettingsModule from './modules/services_settings/certificates_settings/certificates_settings'
//Excuse Module
// import excuseModule from '@/store/modules/study/excuses/excuses'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        users: usersModule,
        // employees: employeeModule,
        // students: studentModule,
        // instructors: instructorModule,
        // programs: programModule,
        // faculties: facultyModule,
        // courses: courseModule,
        // bylaws: bylawModule,
        // chats: chatModule,
        roles: roleModule,
        log: logModule,
        settings: settingModule,
        dashboard: dashboardModule,
        cases: caseModule,
        patients: patientModule,
        branches : branchModule,
        pullLogs : pullLogModule,
        case_review: caseReviewModule,
        setting:settingsModule, 
        // archive: archiveModule,
        // offerings: offeringModule,
        // terms: termModule,
        // registrations: registrationModule,
        // offeringsRegistrations: offeringsRegistrationModule,
        // exams: examModule,
        // proctors: proctorModule,
        // preferences: preferenceModule,
        // schedule: scheduleModule,
        // payments: paymentModule,
        lookups: lookupsModule,
        // transactions: transactionModule,
        // credits: creditModule,
        // departments: departmentModule,
        // certificates: certificateModule,
        // applicants: applicantModule,
        // questions: questionModule,
        // forms: formModule,
        // graduate_questionnaires: graduate_questionnaireModule,
        // student_questionnaires: student_questionnaireModule,
        // faculty_questionnaires: faculty_questionnaireModule,
        // projects: projectModule,
        // publications: publicationModule,
        // theses: thesesModule,
        // sections: sectionModule,
        // markCategories: markCategoryModule,
        // marks: markModule,
        // termStages: termStageModule,
        // certificateSettings: certificateSettingsModule,
        // excuses: excuseModule,
    },
})
