import Resource from '../../api/resource';
import request from '../../utils/request';

class Dashboard extends Resource {
  constructor() {
    super('dashboard');
  }

  counts(){
    return request({
      url: '/dashboard/counts',
      method: 'post'
    });
  }

  faculty(){
    return request({
      url: '/dashboard/faculty',
      method: 'post'
    });
  }

  Activities(){
    return request({
      url: '/dashboard/activities',
      method: 'post'
    });
  }


  doctors(query){
    return request({
      url: '/dashboard/doctors',
      method: 'post',
      data : query
    });
  }

  branches(query){
    return request({
      url: '/dashboard/branches',
      method: 'post',
      data : query
    });
  }

  // add method 
  pullLogs(query){
    return request({
      url: '/dashboard/pullLogs',
      method: 'post',
      data : query
    });
  }

  auditors(query){
    return request({
      url: '/dashboard/auditors',
      method: 'post',
      data : query
    });
  }
  paymentPerMonth(){
    return request({
      url: '/dashboard/payment',
      method: 'post'
    });
  }


  




 
}

export { Dashboard as default };
