import Dashboard from '../../../api/system/dashboard';

const dashboard = new Dashboard();

const locationModule = {
  namespaced: true,
  state: {
    activities: null,
    doctors: null,
    monthly_trend:null,
    monthly_trend_doctor:null,
    monthly_trend_branch:null,
    doctors_lookups:null,
    duration:null,
    auditors: null,
    all_auditors:null,
    auditors_lookups:null,
    stacked_cases:null,
    stacked_cases_branch:null,
    stacked_cases_doctor:null,
    valid_cases_count:null,
    invalid_cases_count:null,
    valid_cases_count_per_month:null,
    invalid_cases_count_per_month:null,

    total_cases:null,
    to_be_reviewed_cases:null,
    assigned_cases:null,
    closed_cases:null,
    pending_branch_cases:null,
    branches:null,
    // add
    pullLogs:null,
    branches_lookups:null,
    counts: null,
    payment: null,
    faculty:null,
   
  },
  getters: {
    counts(state) {
      return state.counts
    },
    faculty(state) {
      return state.faculty
    },
    payment(state) {
      return state.payment
    },
    activities(state) {
      return state.activities
    },
    doctors(state) {
      return state.doctors
    },
    doctors_lookups (state) {
      return state.doctors_lookups
    },

    valid_cases_count_per_month (state) {
      return state.valid_cases_count_per_month
    },
    invalid_cases_count_per_month(state) {
      return state.invalid_cases_count_per_month
    },
    valid_cases_count (state) {
      return state.valid_cases_count
    },
    invalid_cases_count (state) {
      return state.invalid_cases_count
    },
    total_cases (state) {
      return state.total_cases
    },
    branches(state) {
      return state.branches
    },
    // add pull
    pullLogs(state) {
      return state.pullLogs
    },
    branches_lookups (state) {
      return state.branches_lookups
    }
    ,auditors(state) {
      return state.auditors
    },all_auditors(state) {
      return state.all_auditors
    },
    auditors_lookups (state) {
      return state.auditors_lookups
    },
    assigned_cases (state) {
      return state.assigned_cases
    }, pending_branch_cases (state) {
      return state.pending_branch_cases
    },  to_be_reviewed_cases (state) {
      return state.to_be_reviewed_cases
    } , closed_cases (state) {
      return state.closed_cases
    },
    duration(state)
    {
      return state.duration
    },
    monthly_trend(state)
    {
      return state.monthly_trend
    },
    monthly_trend_doctor(state)
    {
      return state.monthly_trend_doctor
    },
    monthly_trend_branch(state)
    {
      return state.monthly_trend_branch
    },
    stacked_cases(state)
    {
      return state.stacked_cases
    }
    , 
     stacked_cases_doctor(state)
    {
      return state.stacked_cases_doctor
    },
      stacked_cases_branch(state)
    {
      return state.stacked_cases_branch
    }
  },
  mutations: {
    SET_COUNTS(state, counts) {
      state.counts = counts;
    },
    SET_FACULTY(state, faculty) {
      state.faculty = faculty;
    },
    SET_ACTIVITIES(state, activities) {
      state.activities = activities;
    },
     SET_DOCTORS(state, doctors) {
      state.doctors = doctors;
    },
    SET_DOCTORS_LOOKUPS(state, doctors_lookups) {
      state.doctors_lookups = doctors_lookups;
    },



    SET_AUDITORS(state, auditors) {
      state.auditors = auditors;
    }, SET_ALL_AUDITORS(state, all_auditors) {
      state.all_auditors = all_auditors;
    },
    SET_DURATION(state, duration)
    {
      state.duration = duration
    },
    SET_AUDITORS_LOOKUPS(state, auditors_lookups) {
      state.auditors_lookups = auditors_lookups;
    },
   
    SET_ASSIGNED_CASES(state, assigned_cases) {
      state.assigned_cases = assigned_cases;
    },
    SET_CLOSED_CASES(state, closed_cases) {
      state.closed_cases = closed_cases;
    },
    SET_TO_BE_REVIEWED_CASES(state, to_be_reviewed_cases) {
      state.to_be_reviewed_cases = to_be_reviewed_cases;
    },
    SET_PENDING_BRANCH_CASES(state, pending_branch_cases) {
      state.pending_branch_cases = pending_branch_cases;
    },

    SET_VALID_CASES_COUNT(state, valid_cases_count) {
      state.valid_cases_count = valid_cases_count;
    },
    SET_INVALID_CASES_COUNT(state, invalid_cases_count) {
      state.invalid_cases_count = invalid_cases_count;
    },

    SET_VALID_CASES_COUNT_PER_MONTH(state, valid_cases_count_per_month) {
      state.valid_cases_count_per_month = valid_cases_count_per_month;
    },
    SET_INVALID_CASES_COUNT_PER_MONTH(state, invalid_cases_count_per_month) {
      state.invalid_cases_count_per_month = invalid_cases_count_per_month;
    },


    SET_TOTAL_CASES(state, total_cases) {
      state.total_cases = total_cases;
    },
    
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },

    SET_PULLLOGS(state, pullLogs) {
      state.pullLogs = pullLogs;
    },

    SET_MONTHLY_TREND(state, monthly_trend) {
      state.monthly_trend = monthly_trend;
    },
    SET_MONTHLY_TREND_DOCTOR(state, monthly_trend) {
      state.monthly_trend_doctor = monthly_trend;
    },
    SET_MONTHLY_TREND_BRANCH(state, monthly_trend) {
      state.monthly_trend_branch = monthly_trend;
    },
    SET_BRANCHES_LOOKUPS(state, branches_lookups) {
      state.branches_lookups = branches_lookups;
    },
    SET_PAYMENT(state, payment) {
      state.payment = payment;
    },
    SET_STACKED_CASES(state, stacked_cases) {
      state.stacked_cases = stacked_cases;
    },
     SET_STACKED_CASES_DOCTOR(state, stacked_cases) {
      state.stacked_cases_doctor = stacked_cases;
    },
    SET_STACKED_CASES_BRANCH(state, stacked_cases) {
      state.stacked_cases_branch = stacked_cases;
    },
  },
  actions: {

    counts({ commit }) {
      return new Promise((resolve, reject) => {
        dashboard.counts()
          .then(response => {
            commit('SET_COUNTS', response.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    faculty({ commit }) {
      return new Promise((resolve, reject) => {
        dashboard.faculty()
            .then(response => {
              commit('SET_FACULTY', response.data);
              resolve();
            })
            .catch(error => {
              reject(error);
            });
      });
    },

    Activities({ commit }) {
      return new Promise((resolve, reject) => {
        dashboard.Activities()
          .then(response => {
            commit('SET_ACTIVITIES', response.data.activities_per_month);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    doctors({ commit } , payload) {
      return new Promise((resolve, reject) => {
        dashboard.doctors(payload)
          .then(response => {
            commit('SET_DOCTORS', response.data.doctors);
            commit('SET_DOCTORS_LOOKUPS', response.data.doctors_lookups);
            commit('SET_VALID_CASES_COUNT', response.data.valid_cases_count);
            commit('SET_INVALID_CASES_COUNT', response.data.invalid_cases_count[0]);
            // commit('SET_VALID_CASES_COUNT_PER_MONTH', response.data.valid_cases_count_per_month);
            // commit('SET_INVALID_CASES_COUNT_PER_MONTH', response.data.invalid_cases_count_per_month);
            commit('SET_TOTAL_CASES', response.data.total_cases);
            commit('SET_STACKED_CASES_DOCTOR', response.data.stacked_cases);
            commit('SET_MONTHLY_TREND_DOCTOR', response.data.monthly_trend);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    branches({ commit } , payload) {
      return new Promise((resolve, reject) => {
        dashboard.branches(payload)
          .then(response => {
            commit('SET_BRANCHES', response.data.branches);
            commit('SET_BRANCHES_LOOKUPS', response.data.branches_lookups);
            commit('SET_VALID_CASES_COUNT', response.data.valid_cases_count);
            commit('SET_INVALID_CASES_COUNT', response.data.invalid_cases_count[0]);
            // commit('SET_VALID_CASES_COUNT_PER_MONTH', response.data.valid_cases_count_per_month);
            // commit('SET_INVALID_CASES_COUNT_PER_MONTH', response.data.invalid_cases_count_per_month);
            commit('SET_TOTAL_CASES', response.data.total_cases);
            commit('SET_STACKED_CASES_BRANCH', response.data.stacked_cases);
            commit('SET_MONTHLY_TREND_BRANCH', response.data.monthly_trend);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // add pull
    pullLogs({ commit } , payload) {
      return new Promise((resolve, reject) => {
        dashboard.pullLogs(payload)
          .then(response => {
            commit('SET_PULLLOGS', response.data.pullLogs);
            // commit('SET_BRANCHES_LOOKUPS', response.data.branches_lookups);
            // commit('SET_VALID_CASES_COUNT', response.data.valid_cases_count);
            // commit('SET_INVALID_CASES_COUNT', response.data.invalid_cases_count[0]);
            // commit('SET_VALID_CASES_COUNT_PER_MONTH', response.data.valid_cases_count_per_month);
            // commit('SET_INVALID_CASES_COUNT_PER_MONTH', response.data.invalid_cases_count_per_month);
            // commit('SET_TOTAL_CASES', response.data.total_cases);
            // commit('SET_STACKED_CASES_BRANCH', response.data.stacked_cases);
            // commit('SET_MONTHLY_TREND_BRANCH', response.data.monthly_trend);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    auditors({ commit } , payload) {
      return new Promise((resolve, reject) => {
        dashboard.auditors(payload)
          .then(response => {
            // commit('SET_AUDITORS', response.data.auditors);
            commit('SET_AUDITORS_LOOKUPS', response.data.auditors_lookups);
            commit('SET_AUDITORS', response.data.auditors);
            commit('SET_ALL_AUDITORS', response.data.all_auditors);
            commit(  'SET_ASSIGNED_CASES', response.data.assigned_cases);
            commit(    'SET_CLOSED_CASES', response.data.closed_cases);
        commit('SET_TO_BE_REVIEWED_CASES', response.data.to_be_reviewed_cases);
        commit('SET_PENDING_BRANCH_CASES', response.data.pending_branch_cases);
        commit('SET_DURATION', response.data.chart_duration);
        commit('SET_MONTHLY_TREND', response.data.monthly_trend);
       
         
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    paymentPerMonth({ commit }) {
      return new Promise((resolve, reject) => {
        dashboard
          .paymentPerMonth()
          .then(response => {
            commit('SET_PAYMENT', response.data.payments_per_month);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};

export default locationModule;
